.home-container {
    font-family: Inter;
    font-weight: 400;
    line-height: 1.15;
    user-select: none;
  }
  
  body,
  html {
    overflow: hidden;
  }